<template>
    <div class="index">
        <!-- 预约详情 -->
        <div class="detail">
            <p class="title">预约信息</p>
            <div class="item">
                <span class="left">预约人：</span>
                <span>{{ consultInfo.nickname }}</span>
            </div>
            <div class="item">
                <span class="left">预约时间：</span>
                <span>{{ consultInfo.date }} {{ consultInfo.week }} {{ consultInfo.type }}</span>
            </div>
            <div class="item">
                <span class="left">预约地点：</span>
                <span>{{ consultInfo.address }}</span>
            </div>
            <div class="item">
                <span class="left">预约咨询师：</span>
                <span>{{ consultInfo.consultName }}（{{ consultInfo.title }}）</span>
            </div>
            <div class="item" v-if="settingInfo.reserveIsPayment === true">
                <span class="left">预约费用：</span>
                <span>￥{{ consultInfo.price }}</span>
            </div>
        </div>
        <div class="btn" @click="secondPay">
            <span>确认预约</span>
        </div>
        <el-dialog :visible.sync="dialogVisible" :before-close="handleClose" :show-close="false"
            :destroy-on-close="true" @closed="closeTimer">
            <div class="dialogBox">
                <div class="containerBox">
                    <span style="font-size:18px; font-weight: 600;">微信支付</span>
                    <div id="qrcode" ref="qrCodeUrl" style="margin-top:30px">
                    </div>
                    <span
                        style="display:inline-block; width:200px; box-sizing: border-box; height: 60px;border:1px solid #eee; line-height: 30px; text-align: center; margin-top: 30px;">请使用微信扫一扫进行支付
                        <br />
                        (请在五分钟内完成支付)</span>

                </div>

                <img src="../../assets/home/weChatPhone.png" alt="" class="payImg">
            </div>

            <!-- <span>这是一段信息</span> -->
            <!-- <span slot="footer" class="dialog-footer">
                
            </span> -->
        </el-dialog>
        <SuccessVue ref="success"></SuccessVue>
    </div>
</template>

<script>
import SuccessVue from './Success.vue';
import { getConsultDetail, userConsult } from '@/api/record'
import { getSetting } from '@/api/setting'

import { getCode, payStatus, payTel, payWeChat } from '@/api/RecordPay'
import QRCode from 'qrcodejs2';//引入生成二维码插件
export default {
    components: { SuccessVue },
    data() {
        return {
            getInfo: {}, // 获取传过来的数据
            consultInfo: {}, //预约信息
            settingInfo: {},
            dialogVisible: false,
            orderNum: null, // 扫码支付返回的订单Number
            timer: 0, // 定时器，用于查询用户的支付状态
            consultType: 1
        }
    },
    methods: {
        // 确认预约
        async submit() {
            let info = {type: this.consultType, ...this.getInfo}
            const res = await userConsult(JSON.stringify(info));
            console.log(res);
            if (res.code === 200) {
                this.$refs.success.open(res.data);
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '预约失败！' + res.msg
                });
            }

            // this.$router.push('/home/consulting')
        },
        // 再次付费
        secondPay() {
            // console.log(this.$route.query.type);
            let payType = this.$route.query.type;
            // 判断是不是第二次支付
            if (this.$route.query.type) {
                console.log('是第二次支付');
                console.log(payType)
                let paymentInfo = JSON.parse(sessionStorage.getItem('paymentInfo'));
                if (payType === 1 || payType === '1') {// 扫码支付
                    // 打开二维码模态框
                    this.dialogVisible = true;
                    // 生成动态二维码
                    this.creatQrCode(paymentInfo.codeUrl);
                    this.orderNum = paymentInfo.ordersNumber;
                    // 定时器发起查询用户的支付状态
                    this.timer = setInterval(() => {
                        this.queryOrderStatus()
                    }, 3000);
                } else if (payType === 2 || payType === '2') {//H5支付
                    window.location.href = paymentInfo.h5_url;
                    this.timer = setInterval(() => {
                        this.queryOrderStatus()
                    }, 3000);
                } else if (payType === 3 || payType === '3') {//js支付
                    this.prepayId = paymentInfo;
                    this.orderNum = paymentInfo.ordersNumber;
                    this.onBridgeReady();
                }
            } else {
                console.log('第一次支付')
                this.clickRecord();
            }
        },
        //点击预约
        async clickRecord() {
            if (this.settingInfo.reserveIsPayment === true) {
                // this.dialogVisible = true;
                if (this._isMobile()) {
                    // openid  先判断用户是否是微信授权登录
                    //判断是否是微信浏览器
                    var ua = navigator.userAgent.toLowerCase();

                    // 判断是否为微信内置浏览器
                    if (typeof WeixinJSBridge === "undefined") {
                        // 非内置微信浏览器
                        if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
                        } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
                            document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
                        }
                        const res = await payTel(sessionStorage.getItem('consultInfo'));
                        console.log(res);
                        if (res.code === 200) {
                            window.location.href = res.data.h5_url;
                            this.timer = setInterval(() => {
                                this.queryOrderStatus()
                            }, 3000);
                        }
                    }
                    else {
                        // 是微信内置浏览器

                        // h5支付需要openID所以需要判断是否打开了使用微信登陆
                        if (this.settingInfo.wechatLogin === 1) {
                            if (JSON.parse(localStorage.getItem('userInfo')).openid) {
                                // H5支付需要用户的openID
                                // 1.先获取支付prepayid
                                const res = await payWeChat(sessionStorage.getItem('consultInfo'));
                                console.log(res);
                                // alert(res)
                                if (res.code === 200) {
                                    // alert(res.data)
                                    this.prepayId = res.data;
                                    this.orderNum = res.data.ordersNumber;
                                    this.onBridgeReady();
                                } else {
                                    // alert('prepayId获取失败');
                                    this.$message({
                                        showClose: true,
                                        message: 'prepayId获取失败！' + res.msg,
                                        type: 'error'
                                    });
                                }

                            } else {
                                this.$message({
                                    showClose: true,
                                    message: '请使用微信授权登录！'
                                });
                            }
                        } else {
                            this.$message({
                                showClose: true,
                                message: '请使用手机其他浏览器打开此网站完成支付！'
                            });
                        }


                    }
                } else {
                    // alert("pc端");
                    this.payOrder();
                }
            } else {
                //没有对接微信支付
                this.submit();
            }
        },
        // 获取详情
        async getDetail() {
            const res = await getConsultDetail(JSON.stringify(this.getInfo));
            console.log(res);
            if (res.code === 200) {
                this.consultInfo = res.data;
                this.consultType = res.data.type
                // 转换星期
                if (this.consultInfo.week === 1) {
                    this.consultInfo.week = '周一'
                } else if (this.consultInfo.week === 2) {
                    this.consultInfo.week = '周二'
                } else if (this.consultInfo.week === 3) {
                    this.consultInfo.week = '周三'
                } else if (this.consultInfo.week === 4) {
                    this.consultInfo.week = '周四'
                } else if (this.consultInfo.week === 5) {
                    this.consultInfo.week = '周五'
                } else if (this.consultInfo.week === 6) {
                    this.consultInfo.week = '周六'
                } else if (this.consultInfo.week === 7) {
                    this.consultInfo.week = '周日'
                }
                // 转换时间段
                if (this.consultInfo.type === 1) {
                    this.consultInfo.type = '全天'
                } else if (this.consultInfo.type === 2) {
                    this.consultInfo.type = '上午'
                } if (this.consultInfo.type === 3) {
                    this.consultInfo.type = '下午'
                }
            }
        },
        // 动态生成二维码
        creatQrCode(val) {
            this.$nextTick(() => {
                this.$refs.qrCodeUrl.innerHTML = '';//二维码清除
                new QRCode(this.$refs.qrCodeUrl, {
                    text: val, // 需要转换为二维码的内容
                    width: 200,
                    height: 200,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            });
        },
        // 查询用户支付状态
        async queryOrderStatus() {
            const res = await payStatus(this.orderNum);
            // 如果用户支付成功
            if (res.code === 2) {
                // 如果用户支付成功则清除查询的定时器
                clearInterval(this.timer);
                // console.log('支付成功！');
                // this.$message({
                //     showClose: true,
                //     message: '支付成功！',
                //     type: 'success'
                // });
                // 关闭支付二维码弹框
                this.dialogVisible = false;
                // this.submit();
                // 打开支付成功的弹框
                setTimeout(() => {
                    this.$refs.success.open(res.data);
                }, 1000);

                // setTimeout(() => {

                // }, 3000);
            }
        },
        // 关闭模态框时，关闭定时器
        closeTimer() {
            clearInterval(this.timer)
        },
        // 微信内置浏览器
        onBridgeReady() {
            //     this.$message({
            //             showClose: true,
            //             message: '微信内置浏览器！'
            //         });
            // alert('onBridgeReady' + this.prepayId);
            this.timer = setInterval(() => {
                this.queryOrderStatus()
            }, 3000);
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                "appId": this.prepayId.appId,     //公众号ID，由商户传入     
                "timeStamp": this.prepayId.timeStamp,         //时间戳，自1970年以来的秒数     
                "nonceStr": this.prepayId.nonceStr, //随机串     
                "package": this.prepayId.package,
                "signType": "RSA",         //微信签名方式：     
                "paySign": this.prepayId.paySign, //微信签名 
                // success: function (res) {
                //     if (res.err_msg == "get_brand_wcpay_request:ok") {
                //         // alert('支付成功！');
                //         this.$message({
                //             showClose: true,
                //             message: '支付成功！',
                //             type: 'success'
                //         });
                //         this.planInfo.ordersStatus = 1;
                //         localStorage.setItem('planInfo', JSON.stringify(this.planInfo));
                //         this.dialogVisible = false;
                //         window.location.reload();
                //         // 使用以上方式判断前端返回,微信团队郑重提示：
                //         //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                //     } else {
                //         this.$message({
                //             showClose: true,
                //             message: '支付失败！',
                //             type: 'error'
                //         });
                //     }
                // },
            });


        },
        // 点击支付 点击支付时获取支付二维码 PC端支付
        async payOrder() {
            const res = await getCode(sessionStorage.getItem('consultInfo'));
            console.log(res);
            if (res.code === 200) {
                // 打开二维码模态框
                this.dialogVisible = true;
                // 生成动态二维码
                this.creatQrCode(res.data.codeUrl);
                this.orderNum = res.data.ordersNumber;
                // 定时器发起查询用户的支付状态
                this.timer = setInterval(() => {
                    this.queryOrderStatus()
                }, 3000);
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '预约失败！' + res.msg
                });
            }
        },
        // 判断是否是手机
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        //直接关闭模态框
        handleClose(done) {
            this.$confirm('关闭后五分钟内无法再次预约，确认关闭？')
                .then(_ => {
                    done();
                    clearInterval(this.timer);
                    this.$router.push('/home/consultDetails')
                })
                .catch(_ => { });
        },
        // 获取配置信息
        async getLogs() {
            const res = await getSetting();
            // console.log(res);
            if (res.code === 200) {
                this.settingInfo = res.data;
            }
        },
    },
    created() {
        // console.log(this.$route.query)
        this.getInfo = JSON.parse(sessionStorage.getItem('consultInfo'));
        this.getDetail();
        this.getLogs();
    }
}
</script>

<style lang="scss" scoped>
.index {
    p {
        margin: 0;
    }

    .dialogBox {
        display: flex;
        justify-content: space-between;
        padding: 0px 30px;
    }

    .detail {
        width: 100%;
        // height: 300px;
        border-radius: 19px;
        border: 1px solid #E7E7E7;
        padding: 30px;
        box-sizing: border-box;

        .title {
            font-size: 20px;
            font-weight: 600;
            color: #303030;
        }

        .item {
            margin-top: 20px;

            span {
                font-size: 16px;
                color: #7D7D7D;
                line-height: 22px;
                // letter-spacing: 10px;
            }

            .left {
                color: #303030;
                // display: inline-block;
                // width: 100px;
                // text-align: right;
                // letter-spacing: 3px;
            }

        }
    }

    .btn {
        cursor: pointer;
        width: 100%;
        height: 40px;
        background: var(--custom-color);
        text-align: center;
        margin-top: 20px;
        border-radius: 3px;
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.15);

        span {
            color: white;
            line-height: 40px;
            font-size: 14px;
            // font-weight: 600;
        }
    }
}

@media screen and (min-width: 751PX) {
    .index {
        padding: 20px 125px;

        .containerBox {
            padding-top: 50px;
        }

        ::v-deep .el-dialog {
            width: 700px !important;
        }

        .payImg {
            height: 430px;
        }
    }
}

@media screen and (max-width: 750PX) {
    .index {
        padding: 20px;

        .payImg {
            display: none;
        }

        ::v-deep .el-dialog {
            width: 300px !important;
        }
    }
}
</style>