<template>
    <div class="index">
        <!-- 预约成功 -->
        <el-dialog :show-close="false" :before-close="handleClose" :visible.sync="dialogVisible" width="350px" >
            <div class="success">
                <img src="../../assets/home/success.png" alt="">
                <span>预约成功!</span>
            </div>
            <div class="detail">
            <p class="title">预约信息</p>
            <div class="item">
                <span class="left">预约人：</span>
                <span>{{ consultInfo.nickname }}</span>
            </div>
            <div class="item">
                <span class="left">预约时间：</span>
                <span>{{ consultInfo.date }} {{ consultInfo.week }} 
                    <br/> {{ consultInfo.type }} {{ consultInfo.startTime }} - {{ consultInfo.endTime }} </span>
            </div>
            <div class="item">
                <span class="left">预约地点：</span>
                <span>{{ consultInfo.address }}</span>
            </div>
            <div class="item">
                <span class="left">预约咨询师：</span>
                <span>{{ consultInfo.consultName }}（{{ consultInfo.title }}）</span>
            </div>
            <div class="item" v-if="settingInfo.reserveIsPayment === true">
                <span class="left">预约费用：</span>
                <span>￥{{ consultInfo.price }}</span>
            </div>
        </div>
            <!-- <span>这是一段信息</span> -->
            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="dialogVisible = false" size="mini">取 消</el-button> -->
                <el-button type="primary" @click="cancel" size="mini">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getSetting } from '@/api/setting'
export default {
    data() {
        return {
            dialogVisible: false,
            consultInfo: {},
            settingInfo: {}
        }
    },
    methods: {
        // 打开当前模态框
        open(val) {
            console.log(val)
            this.getLogs();
            this.consultInfo = val;
            // 转换星期
            if (this.consultInfo.week === 1) {
                    this.consultInfo.week = '周一'
                } else if (this.consultInfo.week === 2) {
                    this.consultInfo.week = '周二'
                } else if (this.consultInfo.week === 3) {
                    this.consultInfo.week = '周三'
                } else if (this.consultInfo.week === 4) {
                    this.consultInfo.week = '周四'
                } else if (this.consultInfo.week === 5) {
                    this.consultInfo.week = '周五'
                } else if (this.consultInfo.week === 6) {
                    this.consultInfo.week = '周六'
                } else if (this.consultInfo.week === 7) {
                    this.consultInfo.week = '周日'
                }
                // 转换时间段
                if (this.consultInfo.type === 1) {
                    this.consultInfo.type = '全天'
                } else if (this.consultInfo.type === 2) {
                    this.consultInfo.type = '上午'
                } if (this.consultInfo.type === 3) {
                    this.consultInfo.type = '下午'
                }
            this.dialogVisible = true;
        },
        // 预约成功返回咨询师列表
        cancel() {
            this.$router.push('/home/consultDetails')
        },
         // 获取配置信息
         async getLogs() {
            const res = await getSetting();
            // console.log(res);
            if (res.code === 200) {
                this.settingInfo = res.data;
            }
        },
        handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
            this.$router.push('/home/consultDetails')
          })
          .catch(_ => {});
      }
    }
}
</script>

<style lang="scss" scoped>
.index {
    p {
        margin: 0;
    }
    .success{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 30px;
            margin-right: 5px;
        }
        span{
            font-size: 20px;
            font-weight: 600;
            color: #303030;
        }
    }
    .detail {
        width: 100%;
        // height: 300px;
        border-radius: 19px;
        border: 1px solid #E7E7E7;
        margin-top: 20px;
        padding:10px;
        box-sizing: border-box;

        .title {
            font-size: 18px;
            font-weight: 600;
            color: #303030;
        }

        .item {
            margin-top: 20px;

            span {
                font-size: 16px;
                color: #7D7D7D;
                line-height: 22px;
                // letter-spacing: 10px;
            }

            .left {
                color: #303030;
                // display: inline-block;
                // width: 100px;
                // text-align: right;
                // letter-spacing: 3px;
            }

        }
    }
    ::v-deep .el-dialog__header{
        padding: 0 !important;
        margin-bottom: 0px !important;
    }
}

</style>