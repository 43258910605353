// 预约支付
import service from '@/utils/request'
// 获取预约订单二维码
export function getCode(data) {
  return service({
    url: '/wx-pay/native/reserve',
    method: 'post',
    data
  });
}
// 查询订单状态
export function payStatus(number,params) {
    return service({
      url: `/orders/reserve/status/${number}`,
      method: 'get',
      params
    });
  }
    // H5订单支付
    export function payTel(data) {
      return service({
        url: `/wx-pay/h5/reserve`,
        method: 'post',
        data
      });
  }

    // 公众号支付
    export function payWeChat( data) {
      return service({
        url: `/wx-pay/js/reserve`,
        method: 'post',
        data
      });
  }